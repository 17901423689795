import { getCip30Wallet } from '@src/utils/kuberUtils';
import type { IConnectedWallet } from '@src/store/user/wallet';
import { removeWallet } from '@src/store/user/wallet';
import type { PersistPartial } from 'redux-persist/es/persistReducer';

export const checkNetworkChange = async (
  wallet: IConnectedWallet & PersistPartial,
  dRepLogout: any,
  dispatch: any
) => {
  const walletFromKuber = await getCip30Wallet(wallet);
  if (!walletFromKuber) {
    console.error('Error Enabling Wallet');
  } else {
    if ((await walletFromKuber.networkId()) !== wallet.network) {
      await dRepLogout().unwrap();
      dispatch(removeWallet());
      return true;
    }
  }
  return false;
};
