import cn from 'classnames';
import React from 'react';
export default function DialogIconWrapper({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={cn(
        'flex h-14 w-14 items-center justify-center rounded-xl border p-[6px] dark:border-white dark:border-opacity-5',
        className
      )}
    >
      <div className="rounded-lg bg-Icon-Wrapper-gradient p-[10px] shadow-Icon-Wrapper-shadow">
        {children}
      </div>
    </div>
  );
}
